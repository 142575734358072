<template>

</template>
<script>
import {defineComponent, h, onMounted} from "vue";
import Icon from '@ant-design/icons-vue'
import SysLogoutSvg from '@/assets/image/timetable/timetable_logout.svg'
import {Modal} from "ant-design-vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "LogoutIcon",
  setup() {
    const router = useRouter()
    const onlogout = () => {
      Modal.confirm({
        content: '是否退出？',
        centered: true,
        maskClosable:true,
        okText: '重新登录',
        cancelText: '关闭云课堂',
        onOk: ()=>{
          router.replace({
            name: 'Login',
          })
        },
        onCancel: ()=>{
          window.electronAPI.closeProject()
        }
      })
    }


    return () => h(<Icon component={SysLogoutSvg} class="logout-icon" onClick={onlogout} />);
  },
  data() {
    return {

    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
    },
  },
})
</script>

<style lang="less" scoped>
.logout-icon {
  position: absolute;
  top: 35px;
  right: 60px;
  font-size: 44px;
  cursor: pointer;
  z-index: 999;
}
</style>
